import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-927dbace"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return ($props.banners)
    ? (_openBlock(), _createBlock(_component_swiper, {
        key: 0,
        class: "banner-slides",
        "slides-per-view": 1,
        breakpoints: $setup.bannerOps.breakpoints,
        autoplay: $setup.bannerOps.autoplay,
        speed: $setup.bannerOps.speed,
        loop: true,
        pagination: true,
        autoHeight: false
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banners, (banner) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, { key: banner }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "banner-img",
                  src: banner.imageLink
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["breakpoints", "autoplay", "speed"]))
    : ($props.products)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 1,
          loop: $props.products.length > $setup.ops.slidesPerView,
          "slides-per-view": Math.min($props.products.length, $setup.ops.slidesPerView),
          breakpoints: $props.products.length >= 3? $setup.ops.breakpoints : {},
          autoplay: true,
          freeMode: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.products, (product) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: product.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_product_card, {
                    product: product,
                    hideDetails: "true"
                  }, null, 8, ["product"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["loop", "slides-per-view", "breakpoints"]))
      : ($props.merchants)
        ? (_openBlock(), _createBlock(_component_swiper, {
            key: 2,
            "slides-per-view": 3,
            breakpoints: $setup.ops.breakpoints,
            autoplay: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.merchants, (merchant) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: merchant.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, {
                      class: "ion-text-center featured-merchant-card",
                      "router-link": {
          name: 'MerchantDetailPage',
          params: { id: merchant.id }
        },
                      button: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: merchant.logoLink,
                          class: "featured-merchant-logo"
                        }, null, 8, _hoisted_2),
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_subtitle, {
                              color: "dark",
                              class: "ion-text-nowrap featured-merchant-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(merchant.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["router-link"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["breakpoints"]))
        : _createCommentVNode("", true)
}