
import { computed, ref } from 'vue';

// icons
import { personCircle, compass, informationCircle, mail, call, location, bag,
        alertCircle, flame, star, searchOutline, notificationsOutline, grid, time, eye, sparkles,} from 'ionicons/icons';

// components
import { IonPage, IonToolbar, IonContent, IonGrid, IonCol, IonRow, IonSpinner,
        IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
        IonSegment, IonSegmentButton, IonLabel, IonAvatar,
        IonButton, IonIcon, modalController, } from '@ionic/vue';
import SectionHeader from '@/components/SectionHeader.vue';
import Slides from "@/components/slides/Slides.vue";
import HomeSection from '@/components/HomeSection.vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import config from '@/config';
import { utils } from '@/composables/utils';
import { Browser } from '@capacitor/browser';

export default {
  name: 'HomePage',
  components: { IonPage, IonToolbar, IonContent, IonGrid, IonCol, IonRow, IonSpinner,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
                IonSegment, IonSegmentButton, IonLabel, IonAvatar, IonButton, IonIcon,
                SectionHeader, Slides, HomeSection, },
  setup() {
    // methods
    const { t } = useI18n();
    const { getLocalizedStr, } = utils();
    const store = useStore();
    const router = useRouter();

    // state variables
    const loadingAppPublicData = computed(() => store.state.loadingAppPublicData);
    const loadingUser = computed(() => store.state.loadingUser);
    const banners = computed(() => store.state.homeBanners);
    const latestProducts = computed(() => store.getters.latestProducts);
    const recentBrowsedProducts = computed(() => store.getters.userRecentBrowsedProducts);
    const allProductCategories = computed(() => store.state.allProductCategories);
    const featuredMerchants = computed(() => store.getters.featuredMerchants);

    const openBrowser = async (url: any) => {
      await Browser.open({ url, toolbarColor: config.primaryColor });
    }

    return {
      // icons
      personCircle, compass, informationCircle, mail, call, location, bag, alertCircle,
      flame, star, searchOutline, notificationsOutline, grid, time, eye, sparkles,
      
      // variables
      loadingUser,
      loadingAppPublicData, banners,
      latestProducts, recentBrowsedProducts, allProductCategories,
      featuredMerchants,

      // methods
      t, getLocalizedStr, openBrowser,
    }
  },
}
