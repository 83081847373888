
// icons
import { alertCircle,star, heartCircleOutline,} from 'ionicons/icons';

// components
import { IonCol, IonRow, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
        IonButtons, IonButton, IonIcon, loadingController, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';

export default {
  props: [
    "merchant",
    "extraRouterParams",
  ],
  components: { IonCol, IonRow, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
                IonButtons, IonButton, IonIcon, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { formatDate, getLocalizedStr, sleep, } = utils();

    const userLoggedIn = computed(() => store.state.loggedIn);

    const updateLikedMerchant = async (merchant: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateLikedMerchant', { merchantId: merchant.id, action });
      await sleep(1.5); // 1.5 seconds
      loading.dismiss();
      merchant.likedByUser = (action == 'add');
    }

    // return variables & methods to be used in template HTML
    return {
      // icons
      alertCircle, star, heartCircleOutline,

      // variables
      userLoggedIn,

      // methods
      t, formatDate, getLocalizedStr,
      updateLikedMerchant,
    }
  }
}
