import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b1e3c72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "card",
    "router-link": {
    name: 'MerchantDetailPage',
    params: { id: $props.merchant.id, ...$props.extraRouterParams }
  },
    button: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "merchant-banner",
              src: $props.merchant.bannerLink
            }, null, 8, _hoisted_2),
            ($setup.userLoggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  ($props.merchant.likedByUser)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        class: "bookmark-icon",
                        icon: $setup.heartCircleOutline,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.updateLikedMerchant($props.merchant, 'remove')), ["stop"])),
                        color: "danger"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true),
                  (!$props.merchant.likedByUser)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        class: "bookmark-icon",
                        icon: $setup.heartCircleOutline,
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.updateLikedMerchant($props.merchant, 'add')), ["stop"]))
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ion_card_subtitle, {
            class: "title",
            color: "dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.merchant.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_subtitle, { class: "products-text" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.getLocalizedStr($props.merchant, 'productCategoryTitles', 'productCategoryTitlesEn')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_subtitle, { class: "products-text" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t('MerchantPage.numOfProducts')) + " " + _toDisplayString($props.merchant.numOfProducts), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}