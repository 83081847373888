
import { computed, } from 'vue';

// icons
import {  } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonRow, IonCol, IonToolbar, IonButtons, IonButton,
        IonSpinner, IonBackButton, IonIcon, IonTitle, } from '@ionic/vue';
import SectionHeader from '@/components/SectionHeader.vue';
import Slides from "@/components/slides/Slides.vue";
import ProductCard from "@/components/product/ProductCard.vue";
import MerchantCard from "@/components/MerchantCard.vue";

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  props: [
    "hideSeeMore",
    "link",
    "title",
    "icon",
    "sectionId",
    "useRowCol",
    "products",
    "merchants",
  ],
  components: { IonHeader, IonGrid, IonRow, IonCol, IonToolbar, IonButtons, IonButton,
                IonSpinner, IonBackButton, IonIcon, IonTitle,
                Slides, SectionHeader, ProductCard, MerchantCard, },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { getLocalizedStr, } = utils();

    const loading = computed(() => store.state.loadingAppPublicData);
    const section = computed(() => store.getters.getHomeSectionById(props.sectionId));
    const sectionProducts = computed(() => store.getters.getProductsBySectionId(props.sectionId));

    return {
      // icons

      // variables
      loading, sectionProducts, section,

      // methods
      t, getLocalizedStr,
    }
  }
}
