
// swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Navigation, Pagination, FreeMode, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// components
import { IonicSlides, IonCard, IonCardHeader, IonCardSubtitle, } from '@ionic/vue';
import ProductCard from '@/components/product/ProductCard.vue';

import config from '@/config';

export default {
  props: [
    "products",
    "merchants",
    "banners",
  ],
  components: { Swiper, SwiperSlide, ProductCard, IonCard, IonCardHeader, IonCardSubtitle, },
  setup() {
    const bannerOps = {
      speed: config.HomePage.banner.slidingSpeed,
      autoplay: {
        delay: config.HomePage.banner.autoPlayDelay,
      },
      breakpoints: {
        // when window width is >= 640px
        1024: {
          slidesPerView: config.HomePage.banner.desktopSlidesPerView,
        },
      },
    };

    const ops = {
      slidesPerView: 2.5,

      breakpoints: {
        // when window width is >= 640px
        768: {
          slidesPerView: 3.5,
        },
        1024: {
          slidesPerView: 4.5,
        }
      }
    };
    return {
      ops,
      bannerOps,

      // swiper
      modules: [Navigation, Pagination, Autoplay, FreeMode, IonicSlides],
    }
  }
}
